<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header my-top-khan-title"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.employees")}}</p>
                    <el-button @click="closeModal()" type="warning" plain>{{$t("message.close")}}</el-button>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 " v-loading="loadStaff">
               <div class="elemet-content">
                  <el-row :gutter="20">
                    <el-col :span="24" v-for="(staff, index) in staff_list"
                            :key="'staff-' + index"
                           >
                        <div class="timeline-items__right rounded-sm w-100  p-3" @click="pushToProfile(staff)" :class="mode ? 'table__myday' : 'table__mynight'">
                            <div class="info-user-left2" :class="mode ? '' : 'performance__itemnight'">
                                <div class="img">
                                    <img
                                      :src="
                                        staff.image
                                          ? baseUrl + staff.image.path
                                          : 'img/avatar.jpeg'
                                      "
                                      alt=""
                                    />
                                  </div>
                                <div class="name-info-user-left">
                                    <div class="name_u">
                                      {{ staff.name + " " + staff.last_name }}
                                    </div>
                                    <div class="position_u" v-if="staff.position">
                                      {{ staff.position.name }}
                                    </div>
                                     <div class="position_u" >
                                        {{ staff.id }}
                                      </div>
                                   
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
               </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import closeDialog from "../../../utils/mixins/dialog"
import drawer from "@/utils/mixins/drawer";
export default {
    name: "show-users",
    mixins: [drawer],
    components: {},
    components: {},
    data() {
        return {
          loadStaff: false,
            baseUrl: process.env.VUE_APP_URL.substring(
              0,
              process.env.VUE_APP_URL.length - 3
            ),
        };
    },
    computed: {
    ...mapGetters({
        staff_list: "additionalWork/staff_list",
        mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
        getStaffList: "additionalWork/staff_list",
    }),

    opened(child) {
      if (!this.loadStaff) {
        this.loadStaff = true;
        this.getStaffList(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }

    },
    pushToProfile(staff) {
      this.$router.push({
        name: "profile",
        params: { id: staff.id },
      });
    },
    closeModal() {
      this.$emit("closeUserModal", false);
    },
  }
};
</script>
<style lang="scss" >
.timeline-items__right {
    margin-bottom: 20px;

    .info-user-left2 {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .img {
            margin-right: 10px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
    }
    .name_u {
        font-weight: bold;
        font-size: 15px;
    }
    .position_u {
        color: #8a8a8a;
        font-size: 12px;
        font-weight: 300;
        margin-top: 2px;
    }
    .salary {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
    }
}
.info-user-left2{
    display: flex;
    align-items: center;
    //background-color: #fff;
    background-clip: border-box;
  //  border: 1px solid rgba(34,41,47,.1254901961);
    border-radius: 0.428rem;
    // box-shadow: 0 4px 24px 0 rgb(34 41 47 / 1%);
    width: 100%;
    cursor: pointer;
    padding: 10px;
}
.my-top-khan-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
     flex-wrap: nowrap;
}
.info-user-left2{
  flex-wrap: nowrap !important;
}
.elemet-content{
  padding: 0 15px;
  height: 100%;
  overflow: auto;
}
.app-modal__box{
  min-height: 100vh;
      background-color: #eef2f4;
}
.el-drawer__body{
  overflow: scroll;
}
</style>
